import React from 'react';
import type { IntlShape } from 'react-intl-next';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import type { AnnotationUpdateEmitter } from '@atlaskit/editor-common/annotation';
import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import Button from '@atlaskit/button/custom-theme-button';
import Tooltip from '@atlaskit/tooltip/Tooltip';
import { default as CommentIconSVG } from '@atlaskit/icon/glyph/comment';
import type { TriggerProps } from '@atlaskit/popup';

import {
	getRendererAnnotationEventEmitter,
	getEditorAnnotationEventEmitter,
} from '@confluence/annotation-event-emitter';
import { useBooleanFeatureFlag, useMultivariantFeatureFlag } from '@confluence/session-data';
import {
	GeneralShortcutListener,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER,
	TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR,
	ShortcutVisualizer,
	ShortcutListener,
} from '@confluence/shortcuts';
import {
	UnreadCommentCount,
	useUnreadInlineComments,
	UnreadTooltipText,
} from '@confluence/unread-comments';
import { useAnnotations, useAnnotationsDispatch } from '@confluence/annotation-provider-store';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import {
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { CommentWarningDialog } from '@confluence/comment-dialogs';

import {
	toggleCommentOldRenderer,
	toggleCommentEditor,
	toggleCommentEditorWithAnnotationManagers,
} from './CommentButtonUtils';

export type PageModeType = 'view' | 'edit';

type CommentIconProps = {
	triggerProps?: TriggerProps;
	intl: IntlShape;
	handleCommentButtonClick: () => void;
	style: React.CSSProperties;
	commentButtonRef?: React.MutableRefObject<HTMLDivElement | null>;
	isDisabled?: boolean;
	isUnreadCommentsEnabled?: boolean;
	contentId?: string;
	pageMode?: PageModeType;
	// isEditorActionBarSSREnabled prop is only used by CommentIconLoadable
	isEditorActionBarSSREnabled?: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CommentButtonWrapper = styled.div({
	display: 'flex',
	flexDirection: 'row',
});

const CommentTooltipText = ({ isUnreadCommentsEnabled, unreadCommentsListState }) => {
	if (isUnreadCommentsEnabled && unreadCommentsListState.length > 0) {
		return <UnreadTooltipText count={unreadCommentsListState.length} />;
	} else {
		return (
			<FormattedMessage
				id="comment-button.tooltip"
				defaultMessage="Show inline comments"
				description="Tooltip for button that shows inline comments for a page"
			/>
		);
	}
};

export const CommentIcon = ({
	triggerProps,
	intl,
	handleCommentButtonClick,
	style,
	commentButtonRef,
	isDisabled = false,
	isUnreadCommentsEnabled = false,
	contentId,
	pageMode = 'view',
}: CommentIconProps) => {
	const { cohort } = useMultivariantFeatureFlag(
		// Prevent circular dependency
		'confluence.frontend.renderer.annotation.provider.inline.comments',
		['annotation-provider', 'not-enrolled', 'query-selectors'],
		'not-enrolled',
		true,
	);
	const isRendererAnnotationProviderEnabled = cohort === 'annotation-provider';
	const [{ unreadCommentsListState }] = useUnreadInlineComments();
	const eventEmitter: AnnotationUpdateEmitter = getRendererAnnotationEventEmitter();
	const editorEmitter = getEditorAnnotationEventEmitter();
	const isEditorAnnotationManagersEnabled = useBooleanFeatureFlag(
		'confluence.frontend.editor.annotation.manager',
	);
	const { editorViewProps } = useAnnotations();
	const { setEditorViewProps } = useAnnotationsDispatch();
	const { showModal } = useDialogs();
	const { hasContentChanged } = useCommentContentContext();
	const { resetContentChanged } = useCommentContentDispatchContext();

	return (
		<CommentButtonWrapper data-testid="comment-icon" ref={commentButtonRef}>
			{pageMode === 'view' && (
				<GeneralShortcutListener
					accelerator={TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER}
					listener={(e) => {
						if (!isRendererAnnotationProviderEnabled) {
							toggleCommentOldRenderer(e, handleCommentButtonClick);
						} else {
							if (document.getElementById('renderer-comments-sidebar')) {
								eventEmitter.emit(AnnotationUpdateEvent.REMOVE_ANNOTATION_FOCUS);
								eventEmitter.emit(AnnotationUpdateEvent.DESELECT_ANNOTATIONS);
							} else {
								handleCommentButtonClick();
							}
						}
					}}
				/>
			)}
			{pageMode === 'edit' && (
				<ShortcutListener
					accelerator={TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR}
					listener={() => {
						// This has to live here so it can be globally listened to, if we put it inside of
						// the sidebar, then it will only trigger when the sidebar has focus
						if (isEditorAnnotationManagersEnabled) {
							// If the content has changed in view, ask the user if they want to discard
							if (editorViewProps && hasContentChanged) {
								showModal(CommentWarningDialog, {
									onConfirm: () => {
										resetContentChanged();
										toggleCommentEditorWithAnnotationManagers(
											editorEmitter,
											handleCommentButtonClick,
											editorViewProps,
											setEditorViewProps,
										);
									},
								});
							} else {
								toggleCommentEditorWithAnnotationManagers(
									editorEmitter,
									handleCommentButtonClick,
									editorViewProps,
									setEditorViewProps,
								);
							}
						} else {
							toggleCommentEditor(editorEmitter, handleCommentButtonClick);
						}
					}}
				/>
			)}
			<Tooltip
				content={
					isUnreadCommentsEnabled && unreadCommentsListState.length > 0 ? (
						<CommentTooltipText
							isUnreadCommentsEnabled={isUnreadCommentsEnabled}
							unreadCommentsListState={unreadCommentsListState}
						/>
					) : (
						<ShortcutVisualizer
							shortcut={
								pageMode === 'edit'
									? TOGGLE_INLINE_COMMENTS_SHORTCUT_EDITOR
									: TOGGLE_INLINE_COMMENTS_SHORTCUT_RENDERER
							}
							contentBefore={
								<CommentTooltipText
									isUnreadCommentsEnabled={isUnreadCommentsEnabled}
									unreadCommentsListState={unreadCommentsListState}
								/>
							}
							isEditorShortcut={pageMode === 'edit'}
						/>
					)
				}
				hideTooltipOnClick
				position="bottom"
			>
				<Button
					testId="comment-button"
					appearance="subtle"
					iconBefore={
						<>
							<CommentIconSVG
								label={
									isUnreadCommentsEnabled && unreadCommentsListState.length > 0
										? intl.formatMessage({
												id: 'comment-button.unread.comments.icon.label',
												description:
													'aria-label for the comment button to display unread inline comments',
												defaultMessage: 'You have unread comments',
											})
										: intl.formatMessage({
												id: 'comment-button.icon.label',
												description:
													'aria-label for the comment button to display the first inline comment',
												defaultMessage: 'Show inline comments',
											})
								}
							/>
							{isUnreadCommentsEnabled && (
								<UnreadCommentCount pageId={contentId!} pageMode={pageMode} />
							)}
						</>
					}
					isDisabled={isDisabled}
					onClick={handleCommentButtonClick}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={style}
					{...(triggerProps || {})}
				/>
			</Tooltip>
		</CommentButtonWrapper>
	);
};
