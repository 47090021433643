import type { FC } from 'react';
import React, { memo, useContext, useState, useRef, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { styled } from '@compiled/react';

import { AnnotationUpdateEvent } from '@atlaskit/editor-common/types';
import type { TriggerProps } from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import Popup from '@atlaskit/popup';
import { N700, N0, N30 } from '@atlaskit/theme/colors';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { withAnalyticsEvents } from '@atlaskit/analytics-next';
import Image from '@atlaskit/image';

import { useRouteActions } from '@confluence/route-manager';
import { getRendererAnnotationEventEmitter } from '@confluence/annotation-event-emitter';
import { useBooleanFeatureFlag, useMultivariantFeatureFlag } from '@confluence/session-data';
import { useInlineComments } from '@confluence/inline-comments-hooks';
import { useUnreadInlineComments, useUnreadCommentsIsEnabled } from '@confluence/unread-comments';
import {
	InlineCommentsContext,
	useCommentContentContext,
	useCommentContentDispatchContext,
} from '@confluence/comment-context';
import { InlineCommentFramework } from '@confluence/inline-comments-common/entry-points/enum';
import {
	VIEW_PAGE_COMMENT_BUTTON_EXPERIENCE,
	VIEW_PAGE_DISCOVER_INLINE_COMMENTS_FEATURE_EXPERIENCE,
	ExperienceSuccess,
	ExperienceTrackerContext,
} from '@confluence/experience-tracker';
import { isElementInViewport } from '@confluence/dom-helpers';
import {
	Attribution,
	ErrorBoundary,
	ErrorDisplay,
	isUnauthorizedError,
} from '@confluence/error-boundary';
import { getLogger } from '@confluence/logger';
import { markErrorAsHandled } from '@confluence/graphql';
import {
	getSortedMarkerRefs,
	setCommentAsActive,
	clearActiveHighlight,
	getMarkerRef,
	scrollCommentIntoView,
} from '@confluence/comments-util';
import { useSubmitSSRScriptErrors } from '@confluence/ssr-scripts-utils';
import { useInlineCommentQueryParams } from '@confluence/comment';
import { useDialogs } from '@confluence/dialogs/entry-points/useDialogs';
import { CommentWarningDialog } from '@confluence/comment-dialogs';

import { IMPROVED_INLINE_COMMENTS_NAVIGATION_FF } from '../entry-points/constants';
import lightModeInlineComment from '../images/lightModeInlineComment.gif';
import darkModeInlineComment from '../images/darkModeInlineComment.gif';

import { CommentButtonPlaceholder } from './CommentButtonPlaceholder';
import { CommentButtonQuery } from './CommentButtonQuery.graphql';
import type {
	CommentButtonQuery as CommentButtonQueryType,
	CommentButtonQuery_comments_nodes_location_InlineComment as CommentButtonQueryInlineCommentLocation,
	CommentButtonQuery_comments_nodes as CommentButtonQueryInlineComment,
} from './__types__/CommentButtonQuery';
import { CommentIcon } from './CommentIcon';

type CommentButtonProps = {
	contentId: string;
	createAnalyticsEvent: CreateUIAnalyticsEvent;
	isFabricPage: boolean;
	isDataLoaded?: boolean;
	isClickedInSSR?: boolean;
};

type CommentContainerProps = {
	isDialogOpen: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderCommentIconContainer = styled.div<CommentContainerProps>({
	borderRadius: '3px',
	maxWidth: '100%',
	minWidth: '30px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) =>
		props.isDialogOpen ? token('color.background.accent.gray.bolder', N700) : '',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		color: (props) => (props.isDialogOpen ? token('color.text.inverse', N0) : 'inherit'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> button:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		background: (props) =>
			props.isDialogOpen
				? token('color.background.accent.gray.bolder', N700)
				: token('color.background.accent.gray.subtler', N30),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentContainer = styled.div({
	width: '241px',
	height: '214px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	padding: `${token('space.200', '16px')} ${token('space.300', '24px')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& h1': {
		fontWeight: 500,
		fontSize: '20px',
		marginBottom: `${token('space.150', '12px')}`,
	},
});

const BUTTON_STYLE_OVERRIDE = {
	padding: `0 ${token('space.050', '4px')} 0 ${token('space.025', '2px')}`,
};

export const inlineCommentGifContent = () => (
	<ContentContainer data-cy="inline-comment-gif">
		<FormattedMessage
			id="comment-button.heading"
			defaultMessage="Be the first to add an inline comment"
			description="Heading Section for Inline Comments discoverability Dialog"
			tagName="h1"
		/>
		<Image
			src={`${lightModeInlineComment}?${new Date().getTime()}`}
			srcDark={`${darkModeInlineComment}?${new Date().getTime()}`}
			alt=""
		/>
		<FormattedMessage
			id="comment-button.message"
			defaultMessage="Share your feedback or ask a question by highlighting text and clicking {text}."
			description="Paragraph Section for Inline Comments discoverability Dialog"
			tagName="p"
			values={{
				text: <b>Comment</b>,
			}}
		/>
	</ContentContainer>
);

const logger = getLogger('comment-button');

/* As user scrolls down Content Header goes out of viewport. When user scrolls up slighlty we display
 Header elements in Sticky Wrapper. Using isElementInViewport to set position of Inline dialog
to always be below the Comment Icon */
export const getPlacement = (commentButton) => {
	if (commentButton) {
		if (isElementInViewport(commentButton)) {
			return 'bottom-end';
		} else {
			return 'auto-end';
		}
	} else {
		return 'bottom-end';
	}
};

export const CommentButtonComponent: FC<CommentButtonProps> = memo(
	({ contentId, createAnalyticsEvent, isDataLoaded, isFabricPage }) => {
		const intl = useIntl();
		const { showModal } = useDialogs();
		const { hasContentChanged } = useCommentContentContext();
		const { resetContentChanged } = useCommentContentDispatchContext();

		const { removeCommentQueryParams } = useInlineCommentQueryParams();

		const { cohort } = useMultivariantFeatureFlag(
			// Prevent circular dependency
			'confluence.frontend.renderer.annotation.provider.inline.comments',
			['annotation-provider', 'not-enrolled', 'query-selectors'],
			'not-enrolled',
			true,
		);
		const isRendererAnnotationProviderEnabled = cohort === 'annotation-provider';
		const isEditorAnnotationProviderManagersEnabled = useBooleanFeatureFlag(
			'confluence.frontend.editor.annotation.manager',
		);

		useSubmitSSRScriptErrors('comment-button-placeholder');
		const [{ unreadCommentsListState }] = useUnreadInlineComments();

		const isFabricPageAndRAP = isFabricPage && isRendererAnnotationProviderEnabled;

		// skip this query for fabric page with RAP
		const { loading, data, error } = useQuery<CommentButtonQueryType>(CommentButtonQuery, {
			variables: { pageId: contentId },
			fetchPolicy: isDataLoaded || window.__SSR_RENDERED__ ? 'cache-first' : 'cache-and-network',
			skip: isFabricPageAndRAP,
		});

		const isUnreadCommentsEnabled = useUnreadCommentsIsEnabled();
		const [{ unresolvedInlineComments: rapUnresolvedInlineComments }] = useInlineComments();
		const { setQueryParams } = useRouteActions();

		/* Read data from graphql Query if non-RAP */

		const inlineCommentsCount = isFabricPageAndRAP
			? rapUnresolvedInlineComments.length
			: data?.comments?.count || 0;

		const unresolvedInlineComments = isFabricPageAndRAP
			? rapUnresolvedInlineComments
			: data?.comments?.nodes || [];

		const [isDialogOpen, setDialogState] = useState(false);
		const [isInlineCommentShown, setShowInlineComments] = useState(false);
		const { onHighlightClick } = useContext(InlineCommentsContext);

		const experienceTracker = useContext(ExperienceTrackerContext);

		const useImprovedInlineCommentsNavigation = useBooleanFeatureFlag(
			IMPROVED_INLINE_COMMENTS_NAVIGATION_FF,
		);

		const inlineCommentRef = useRef<HTMLElement | null>(null);
		const commentButtonRef = useRef<HTMLDivElement | null>(null);
		const isFirstClickRef = useRef<boolean>(true);
		const emitter = getRendererAnnotationEventEmitter();

		useEffect(() => {
			// if the button was clicked in SSR, handle the SSR click immediately upon first
			// component mount
			if (window?.__SSR_EVENTS_CAPTURE__ && window.__SSR_EVENTS_CAPTURE__.inlineCommentButton) {
				handleCommentButtonClick();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);

		// handle button click for legacy editor and non-rap fabric editor
		useEffect(() => {
			if ((!isFabricPageAndRAP ? data : true) && isDataLoaded && isFirstClickRef.current) {
				//only call this if it hasn't been clicked before
				handleCommentButtonClick();
				isFirstClickRef.current = false;
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [data, isDataLoaded]);

		useEffect(() => {
			return () => {
				//Setting to null, since when the component re-renders (when a New comment is added), we want to query the DOM again to get the First Inline comment by position.
				inlineCommentRef.current = null;
			};
			/**
			 * Have to explicitly disable the eslint rule to ensure the value gets reset to null
			 * Saving an inline comment will trigger a re-fetch of `CommentButtonQuery` and a re-render of this component.
			 */
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [inlineCommentRef.current]);

		useEffect(() => {
			experienceTracker.start({
				name: VIEW_PAGE_COMMENT_BUTTON_EXPERIENCE,
				id: contentId,
				attributes: {
					framework: isFabricPageAndRAP
						? InlineCommentFramework.ANNOTATION_PROVIDER
						: InlineCommentFramework.REACT,
				},
			});
		}, [experienceTracker, contentId, isFabricPageAndRAP]);

		//Checks the DOM for the first Inline Comment by position with class valid.
		const getFirstMarkerRefByPosition = () => {
			//checks for both Fabric and Legacy annotations
			const annotations: HTMLElement[] = [].slice.call(
				document.querySelectorAll(
					`[data-mark-annotation-type="inlineComment"],.inline-comment-marker`,
				),
			);

			if (isFabricPageAndRAP) {
				return (
					annotations.find(
						(item) => item.getAttribute('data-mark-annotation-state') === 'active',
					) || null
				);
			}

			return annotations.find((item) => item.classList.contains('inline-highlight')) || null;
		};

		const getClass = () => 'inline-highlight';

		const renderFirstInlineComment = (elem) => {
			// Handles case where when User clicks on inline comments button in SSR
			const isInlineCommentsButtonClickedInSSR =
				window?.__SSR_RENDERED__ && window?.__SSR_EVENTS_CAPTURE__?.inlineCommentButton;

			// follows the SSR click handling logic found in InlineCommentsHighligherNew.tsx
			if (elem && isInlineCommentsButtonClickedInSSR) {
				const markerRef = getMarkerRef(elem);
				setCommentAsActive(markerRef);
				onHighlightClick(markerRef);
				return;
			}
			// Handles case where when User clicks Icon and annotations are not yet in the DOM.
			if (!elem) {
				toggleDialog();
			} else if (elem && elem.classList.contains(getClass())) {
				/**
				 * Checking class valid for edge case scenario in which User visits a page with a Single Inline Comment
				 * and then deletes or resolves a Inline Comment.
				 * Deleting comment removes class valid from the annotation
				 */
				if (!elem.classList.contains('active-highlight')) {
					elem.click();
				}

				if (useImprovedInlineCommentsNavigation && elem.classList.contains('active-highlight')) {
					scrollCommentIntoView(elem, isFabricPage);
				}
			} else {
				/**
				 * Does another check to see if there are any valid inline Comments on the Page.
				 * Handles edge case where we have  multiple Inline comments on the Page and User deletes the first One.
				 * In that case check the DOM to find the Next valid inline Comment and Render
				 * If no valid Inline comment is found it will be handled by above logic
				 */
				renderFirstInlineComment(findFirstInlineComment());
			}
		};

		const findFirstInlineComment = () => {
			const firstInlineCommentByPosition = getFirstMarkerRefByPosition();

			let markerRef =
				firstInlineCommentByPosition &&
				(firstInlineCommentByPosition.getAttribute('data-id') ||
					firstInlineCommentByPosition.getAttribute('data-ref'));

			if (isFabricPageAndRAP) {
				markerRef = firstInlineCommentByPosition && firstInlineCommentByPosition.getAttribute('id');
			}

			//Check against graphql response to make sure data integrity between DOM and back-end
			const isAnnotationInAnntationList =
				(markerRef &&
					Boolean(
						unresolvedInlineComments.find((item) => {
							if (isFabricPageAndRAP) {
								return item === markerRef;
							} else {
								const location = (item as CommentButtonQueryInlineComment)
									?.location as CommentButtonQueryInlineCommentLocation;
								return location.inlineMarkerRef === markerRef;
							}
						}),
					)) ||
				false;

			if (isAnnotationInAnntationList) {
				//store using useRef to avoid multiple function calls.
				inlineCommentRef.current = firstInlineCommentByPosition;
				return firstInlineCommentByPosition;
			}

			return null;
		};

		const toggleDialog = () => {
			setDialogState(!isDialogOpen);
			/**
			 * Scenario: A user clicks the Inline Comments Button in SSR but there are no inline comments.
			 * If there is an SSR click, deleting it here removes the loading spinner only when the dialog is open.
			 * There is similar logic to clean up this click in the scenario where there are inline comments, found
			 * in InlineCommentSidebar.tsx
			 */
			if (window?.__SSR_EVENTS_CAPTURE__?.inlineCommentButton) {
				delete window.__SSR_EVENTS_CAPTURE__.inlineCommentButton;
			}
			fireScreenEvent();

			experienceTracker.succeed({
				name: VIEW_PAGE_DISCOVER_INLINE_COMMENTS_FEATURE_EXPERIENCE,
				attributes: { pageHasInlineComments: false },
			});
		};

		//Screen Event when we display Dialog to show Users how to add Inline comments
		const fireScreenEvent = () => {
			createAnalyticsEvent({
				type: 'sendScreenEvent',
				data: {
					name: 'commentsOnboardingScreen',
					attributes: {
						type: 'header-button',
					},
				},
			}).fire();
		};

		const fireUIEvent = (pageHasInlineComments, shouldShow) => {
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'navigationItem',
					actionSubjectId: 'inlineCommentsIcon',
					attributes: {
						pageHasInlineComments,
						icontype: 'header',
						context: 'default',
						newState: shouldShow ? 'showComments' : 'hideComments',
						unreadCommentCount: unreadCommentsListState?.length,
					},
					objectType: 'page',
					objectId: contentId,
					source: 'viewPageScreen',
				},
			}).fire();
		};

		const checkUnsavedAndHandleCommentButtonClick = () => {
			if (hasContentChanged) {
				showModal(CommentWarningDialog, {
					onConfirm: () => {
						resetContentChanged();
						handleCommentButtonClick();
					},
				});
			} else {
				handleCommentButtonClick();
			}
		};

		const handleCommentButtonClick = () => {
			if (isUnreadCommentsEnabled && unreadCommentsListState?.length && isFabricPageAndRAP) {
				clearActiveHighlight();

				const markerRefList = unreadCommentsListState?.map((comment) => comment?.inlineMarkerRef);
				const sortedUnreadMarkerRefList = getSortedMarkerRefs(markerRefList as string[], 'view');
				const markerRef = sortedUnreadMarkerRefList[0];
				const commentElement = document.getElementById(markerRef);
				if (document.querySelector(`[id="${markerRef}"][data-has-focus="true"]`)) {
					return;
				}
				emitter.emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
					annotationIds: [markerRef],
					eventTarget: commentElement,
				});
				setQueryParams({
					unreadCommentMark: markerRef,
					focusedCommentId: undefined,
				});

				fireUIEvent(true, !isInlineCommentShown);
			} else {
				experienceTracker.start({
					name: VIEW_PAGE_DISCOVER_INLINE_COMMENTS_FEATURE_EXPERIENCE,
					attributes: {
						framework: isFabricPageAndRAP
							? InlineCommentFramework.ANNOTATION_PROVIDER
							: InlineCommentFramework.REACT,
					},
				});

				const pageHasUnresolvedInlineComments = Boolean(
					inlineCommentsCount && unresolvedInlineComments.length > 0,
				);

				if (pageHasUnresolvedInlineComments) {
					try {
						const firstInlineComment = inlineCommentRef.current || findFirstInlineComment();
						if (isFabricPageAndRAP && firstInlineComment?.id) {
							removeCommentQueryParams();
							const commentElement = document.getElementById(`${firstInlineComment?.id}`);
							emitter.emit(AnnotationUpdateEvent.ON_ANNOTATION_CLICK, {
								annotationIds: [firstInlineComment?.id],
								eventTarget: commentElement,
							});
							scrollCommentIntoView(commentElement, true);
						} else {
							renderFirstInlineComment(firstInlineComment);
						}

						// Fire UI event for button click in view mode with inline comments
						fireUIEvent(pageHasUnresolvedInlineComments, !isInlineCommentShown);
						setShowInlineComments(!isInlineCommentShown);
					} catch (error) {
						experienceTracker.fail({
							name: VIEW_PAGE_DISCOVER_INLINE_COMMENTS_FEATURE_EXPERIENCE,
							error,
							attributes: { pageHasInlineComments: true },
						});
						logger.error`An Error occured when computing to search for first Unresolved Inline comment`;
					}
				} else {
					// Fire UI event for button click without inline comments
					fireUIEvent(pageHasUnresolvedInlineComments, !isDialogOpen);
					toggleDialog();
				}
			}
		};

		if (loading) {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			return <CommentButtonPlaceholder style={BUTTON_STYLE_OVERRIDE} />;
		} else if (error && isUnauthorizedError(error)) {
			markErrorAsHandled(error);
			//Abort Experience for anonymous users and don't display Comment Button
			// We have a known error with Comments API that does not handle anonymous Users
			//https://product-fabric.atlassian.net/browse/WS-1759
			experienceTracker.abort({
				name: VIEW_PAGE_COMMENT_BUTTON_EXPERIENCE,
				reason: 'Anonymous user',
			});
			return null;
		}

		const getCommentIcon = (triggerProps: TriggerProps) => {
			return (
				<HeaderCommentIconContainer
					isDialogOpen={isDialogOpen}
					data-cy="comment-button"
					ref={commentButtonRef}
					data-testid={contentId}
				>
					<CommentIcon
						triggerProps={triggerProps}
						intl={intl}
						handleCommentButtonClick={
							isEditorAnnotationProviderManagersEnabled
								? checkUnsavedAndHandleCommentButtonClick
								: handleCommentButtonClick
						}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={BUTTON_STYLE_OVERRIDE}
						isUnreadCommentsEnabled={isUnreadCommentsEnabled}
					/>
				</HeaderCommentIconContainer>
			);
		};

		return (
			<ErrorBoundary attribution={Attribution.COLLABORATION}>
				<Popup
					isOpen={isDialogOpen}
					content={inlineCommentGifContent}
					trigger={(triggerProps) => getCommentIcon(triggerProps)}
					placement={getPlacement(commentButtonRef.current)}
					onClose={() => {
						toggleDialog();
					}}
					shouldRenderToParent
				/>
				<ExperienceSuccess name={VIEW_PAGE_COMMENT_BUTTON_EXPERIENCE} />
				{/*
            In case of graphql Error we will still display the CommentButton since the Component
            handles displaying the GIF showing Users how to add Inline Comments.
            We will call ErrorDisplay to send unhandled Errors
          */}
				{error && <ErrorDisplay error={error} />}
			</ErrorBoundary>
		);
	},
);

export const CommentButton = withAnalyticsEvents()(CommentButtonComponent);
